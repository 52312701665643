import React from "react";

const PersonalDetails = ({ darkTheme }) =>
  // currentTab,
  // setCurrentTab,
  // formDetails,
  // setFormDetails,

  {
    return (
      <>
        {/* <p
        className={`text-mg font-bold ${
          currentTab === 1 ? "text-primaryBlue" : "text-gray-500"
        }`}
      >
        Personal Details
      </p> */}
        {/* <div
        className={`h-auto w-[800px] border-2 border-gray-300 p-6 rounded-md flex flex-col gap-10 xs:gap-4 py-10 xs:w-full xs:px-4`}
      > */}
        <div
          className={`flex flex-col ${
            darkTheme ? `text-textColor` : `text-textSecondaryColor`
          } gap-2`}
        >
          <label htmlFor="" className={` flex items-center gap-2`}>
            Name <span className="text-center text-[#DB5F5F]">*</span>
          </label>
          <input
            type="text"
            className={`outline-none w-full border-b-2 border-textColor  text-gray-500 ${
              darkTheme && ` bg-primaryGray text-textColor`
            }`}
            required
            name="from_name"
          />
        </div>
        <div className={`flex flex-col ${
            darkTheme ? `text-textColor` : `text-textSecondaryColor`
          } gap-2`}>
          <label htmlFor="" className="flex items-center gap-2">
            Email Address <span className="text-center text-[#DB5F5F]">*</span>
          </label>
          <input
            type="email"
            className={`outline-none w-full border-b-2 border-textColor text-gray-500 ${
              darkTheme && ` bg-primaryGray text-textColor`
            }`}
            required
            name="email_address"
          />
        </div>
        <div className="flex w-full gap-4 items-center xs:flex-col">
          <div className={`flex flex-col ${
            darkTheme ? `text-textColor` : `text-textSecondaryColor`
          } gap-2 w-full`}>
            <label htmlFor="" className="flex items-center gap-2">
              Phone Number <span className="text-center text-[#DB5F5F]">*</span>
            </label>
            <input
              type="phone"
              className={`outline-none w-full border-b-2 border-textColor  text-gray-500 ${
                darkTheme && ` bg-primaryGray text-textColor`
              }`}
              required
              name="phone_number"
            />
          </div>
          <div className={`flex flex-col ${
            darkTheme ? `text-textColor` : `text-textSecondaryColor`
          } gap-2 w-full`}>
            <label htmlFor="" className="flex items-center gap-2">
              Are you a wicrypt user?{" "}
              <span className="text-center text-[#DB5F5F]">*</span>
            </label>
            <select
              className={`outline-none w-full border-b-2 border-textColor  text-gray-500 ${
                darkTheme && ` bg-primaryGray text-textColor`
              }`}
              placeholder="Are you a wicrypt user?"
              required
              name="wicrypt_user"
            >
              <option value="" disabled selected hidden></option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
        </div>
        {/* </div> */}
        {/* <button
        className="btn-primary w-[100px]"
        onClick={() => setCurrentTab(2)}
      >
        Next
      </button> */}
      </>
    );
  };

export default PersonalDetails;

import { useState, useRef, useEffect } from "react";
import React from "react";
import { footerList } from "../utils";
import { sendEmail } from "../helpers";

const Footer = ({ darkTheme }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(null);
  const form = useRef();

  useEffect(() => {
    fetch(
      "https://api.geckoterminal.com/api/v2/networks/polygon_pos/tokens/0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689/pools",
      {
        method: "GET", //  'get'
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPrice(data?.data[0]);
        // console.log(data);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);


  return (
    <div
      className={`${
        darkTheme ? `bg-primaryBlack` : `bg-white`
      } w-full h-auto bg-white-50 flex flex-col gap-10 mt-[200px] bottom-0 px-[100px] xs:px-8 sm:px-8 py-[50px] relative `}
    >
      <div className="flex justify-between xs:justify-center w-full gap-40 sm:gap-10 xs:gap-4 xs:flex-col">
        <div className="flex flex-col gap-10 ">
          <img
            src={
              darkTheme ? `/assets/svg/dark/logo2.svg` : `/assets/svg/logo2.svg`
            }
            alt=""
            className="xs:w-40"
          />
          <p className={`text-md ${darkTheme ? `text-white` : `text-black`}`}>
            Join Our Community
          </p>
          <div className="grid grid-cols-4 gap-4 ">
            <a href="https://t.me/wicrypt" target="_blank" rel="noreferrer">
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/telegram.svg`
                    : `/assets/svg/telegram.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://www.instagram.com/wicryptnetwork/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/instagram.svg`
                    : `/assets/svg/instagram.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://twitter.com/wicrypt"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/twitter.svg`
                    : `/assets/svg/twitter.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://www.linkedin.com/company/wicrypt/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/linkedIn.svg`
                    : `/assets/svg/linkedIn.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3NGYpnFSgw9Nj8Vpb18y3g"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/youtube.svg`
                    : `/assets/svg/youtube.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://www.reddit.com/r/Wicrypt/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/reddit.svg`
                    : `/assets/svg/reddit.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://web.facebook.com/wicrypt/?_rdc=1&_rdr"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/facebook.svg`
                    : `/assets/svg/facebook.svg`
                }
                alt=""
              />
            </a>
            <a
              href="https://discord.io/wicrypt"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={
                  darkTheme
                    ? `/assets/svg/dark/discord.svg`
                    : `/assets/svg/discord.svg`
                }
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="grid grid-cols-3 xs:grid-cols-2 xs:gap-4 w-[70%] xs:w-full">
          {footerList?.map((list) => (
            <div className="flex flex-col gap-4" key={list}>
              <p
                className={`${
                  darkTheme ? `text-white` : `text-primaryBlue`
                } text-lg xs:text-sm  font-bold`}
              >
                {list?.title}
              </p>
              {list?.options?.map((option) => (
                <a
                  href={option?.url}
                  target="_blank"
                  rel="noreferrer"
                  className={`${
                    darkTheme ? `text-textColor` : `text-gray-500`
                  }  text-md xs:text-xs`}
                  key={option}
                >
                  {option?.name}
                </a>
              ))}{" "}
            </div>
          ))}
          <div className="flex flex-col gap-4">
            <p
              className={`${
                darkTheme ? `text-white` : `text-primaryBlue `
              } text-2xl xs:text-sm font-bold`}
            >
              Contact Us
            </p>
            <p
              className={`${
                darkTheme ? `text-white` : `text-gray-700`
              } text-md xs:text-sm font-semibold`}
            >
              Address 1:
            </p>
            <p
              className={`${
                darkTheme ? `text-textColor` : `text-gray-700`
              } text-md xs:text-sm font-semibold`}
            >
              5C Bethel Plaza Garden Avenue, Enugu, Nigeria.
            </p>
            <p
              className={`${
                darkTheme ? `text-white` : `text-gray-700`
              } text-md xs:text-md font-semibold`}
            >
              Address 2:
            </p>
            <p
              className={`${
                darkTheme ? `text-textColor` : `text-gray-700`
              } text-md xs:text-md font-semibold`}
            >
              204A Isale Eko Avenue Dolphin Estate Lagos, Nigeria.
            </p>
            <p
              className={`${
                darkTheme ? `text-white` : `text-gray-700`
              } text-md xs:text-md font-semibold`}
            >
              Email:
            </p>
            <p
              className={`${
                darkTheme ? `text-textColor` : `text-gray-700`
              } text-md xs:text-sm font-semibold`}
            >
              hello@wicrypt.com{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="flex xs:flex-col items-center justify-end gap-10">
        <div className="flex gap-4 items-center mr-10">
          <img src="/assets/svg/token.svg" alt="" className="xs:w-20" />
          <div className="flex flex-col item-center gap-2">
            <p
              className={`text-sm ${
                darkTheme ? `text-white` : `text-black`
              } font-bold xs:text-xs`}
            >
              Token Price
            </p>
            <p
              className={`${
                darkTheme ? `text-white` : `text-black`
              } text-lg font-bold xs:text-xs`}
            >
              {Number(price?.attributes?.base_token_price_usd)?.toFixed(6)} USD
            </p>
            <p className="flex items-center gap-2 text-green-500 xs:text-xs">
              <img src="/assets/svg/carret-up.svg" alt="" />
              {price?.attributes?.price_change_percentage?.h24}%
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <p className={`${darkTheme ? `text-white` : `text-black`} font-bold`}>
            Available On
          </p>
          <div className="flex items-center gap-2 sm:flex-col">
            <p className="flex gap-2">
              <img src="/assets/svg/mexc-icon.svg" alt="" />
              <span
                className={`${
                  darkTheme ? `text-white` : `text-black`
                } font-bold`}
              >
                MEXC
              </span>
            </p>
            <p className="flex gap-2">
              <img src="/assets/svg/quickswap-icon.svg" alt="" />
              <span
                className={`${
                  darkTheme ? `text-white` : `text-black`
                } font-bold`}
              >
                Quickswap
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 ">
          <p
            className={`${
              darkTheme ? `text-white` : `text-black`
            } font-bold text-lg`}
          >
            Subscribe to Wicrypt newsletter
          </p>
          <form
            action=""
            ref={form}
            className="flex gap-4 flex-col"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(
                form.current,
                "Subscription Successful",
                "You have successfully subscribed to our newsletter",
                setLoading,
                darkTheme,
                `Subscription failed`,
                `template_imu947r`
              );
            }}
          >
            <input
              type="email"
              className="border-2 rounded-full p-2 border-borderColor text-gray-500 outline-none"
              placeholder="Type in your email"
              value={email}
              onChange={(e) => {
                e.preventDefault();
                setEmail(e.target.value);
              }}
              required
              name="sender_email"
            />
            <input
              type="submit"
              className="btn-primary cursor-pointer"
              disabled={loading ? true : false}
              value={loading ? "Activating..." : "Subscribe"}
            />
          </form>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p
          className={`${
            darkTheme ? `text-textColor` : `text-gray-400`
          } text-sm xs:text-[10px]`}
        >
          Copyright © 2018-2022. All rights reserved. Wicrypt Limited
        </p>
        <p
          className={`${
            darkTheme ? `text-textColor` : `text-black`
          } flex gap-4 xs:text-[10px]`}
        >
          <a href="/">Privacy Policy</a>
          <a href="/">Terms of use</a>
          <a href="/">Help</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;

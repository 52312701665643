export const highModelCategories = [
  {
    name: "1KM SINGLE STREET ",
    options: [
      "Wicrypt Gigabit Router",
      "Wi-Fi6 Access Points",
      "Repeaters (optional)",
      "Minimum Internet Requirement: 100Mbps",
      "Supports up to 300 Concurrent Users",
    ],
  },
  {
    name: "TWO STREETS of 1KM EACH",
    options: [
      "Wicrypt Gigabit Router",
      "Wi-Fi6 Access Points",
      "Repeaters (optional)",
      "Minimum Internet Requirement: 250Mbps",
      "Supports up to 500 Concurrent Users",
    ],
  },
];

export const normalModelCategory = [
  {
    name: "1KM SINGLE STREET ",
    options: [
      "Wicrypt Gigabit Router",
      "Wi-Fi4 Access Points",
      "Repeaters (optional)",
      "Minimum Internet Requirement: 100Mbps",
      "Supports up to 200 Concurrent Users",
    ],
  },
  {
    name: "TWO STREETS of 1KM EACH",
    options: [
      "Wicrypt Gigabit Router",
      "Wi-Fi4 Access Points",
      "Repeaters (optional)",
      "Minimum Internet Requirement: 250Mbps",
      "Supports up to 400 Concurrent Users",
    ],
  },
];

export const devices = [
  {
    name: "Grandstream GWN7660LR",
    image: "assets/svg/device1.svg",
  },
  {
    name: "POE Switch",
    image: "assets/svg/device2.svg",
  },
  {
    name: "Ubiquity Rocket Prism",
    image: "assets/svg/device3.svg",
  },
  {
    name: "Powerbeam 5ac 620",
    image: "assets/svg/device4.svg",
  },
  {
    name: "Nanostation M2",
    image: "assets/svg/device5.svg",
  },
  {
    name: "Nanostation M5",
    image: "assets/svg/device6.svg",
  },
];

export const footerList = [
  {
    title: "Company",
    options: [
      { id: 1, name: "FAQs", url: `https://wicrypt.com/faq.html` },
      { id: 2, name: "Help Center", url: `https://wicrypt.com/help/` },
      {
        id: 3,
        name: "Developer",
        url: `http://stage.wicrypt.com/redoc/public`,
      },
      { id: 4, name: "Blog", url: `https://medium.com/wicrypt` },
      {
        id: 5,
        name: "Media Kits",
        url: `https://drive.google.com/drive/folders/1SmjSVNkhX0mwGu9j5lIenUEGg3J_nn0A?usp=sharing`,
      },
    ],
  },
  {
    title: "Developer",
    options: [
      { id: 1, name: "Token", url: `https://wicrypt.com/token.html` },
      { id: 2, name: "Whitepaper", url: `https://whitepaper.wicrypt.com/` },
      {
        id: 3,
        name: "Explorer",
        url: `https://scan.wicrypt.com/`,
      },
    ],
  },
];

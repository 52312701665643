import React from "react";

const HowItWorks = ({ darkTheme }) => {
  return (
    <div
      className={`w-full h-[70vh] xs:h-auto ${
        darkTheme ? `bg-lightPrimaryBlack` : `bg-white`
      } flex flex-col  items-center gap-20 transition-all duration-300 px-[100px] xs:p-8 py-[50px] xs:py-4" id="how-it-works pt-[70px] xs:pt-[50px]`}
      id="how-it-works"
    >
      <div className="text-center">
        <div className=" relative w-full">
          <p
            className={`${
              darkTheme ? `text-white` : `text-black`
            } text-[30px] xs:text-[24px] font-bold `}
          >
            How does it work?
          </p>
          <div className="w-[91px] xs:h-[5px] h-[10px] bg-primaryYellow absolute left-[40%] bottom-0 "></div>
        </div>
      </div>
      <div className="flex xs:flex-col item-center justify-between gap-40 xs:gap-8 sm:gap-10">
        <div className="flex gap-2 items-center">
          <div className="flex flex-col gap-4">
            <p
              className={`${darkTheme ? `text-white` : `text-black`} font-bold`}
            >
              STEP 1
            </p>
            <p
              className={`${
                darkTheme ? `text-white ` : `text-black`
              } text-[16px] font-bold`}
            >
              Our City WiFi network is built on a meshed setup, where multiple
              nodes communicate wirelessly.
            </p>
          </div>
          <div
            className={`text-[128px] xs:text-[80px] ${
              darkTheme ? `text-primaryYellow` : `text-primaryBlue`
            } font-semibold`}
          >
            1
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex flex-col gap-4">
            <p
              className={`${darkTheme ? `text-white` : `text-black`} font-bold`}
            >
              STEP 2
            </p>
            <p
              className={`${
                darkTheme ? `text-white ` : `text-black`
              } text-[16px] font-bold`}
            >
              A central router powers this network, handling IP assignments and
              authentication when clients connect through any of the nodes.
            </p>
          </div>
          <div
            className={`text-[128px] xs:text-[80px] ${
              darkTheme ? `text-primaryYellow` : `text-primaryBlue`
            } font-semibold`}
          >
            2
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="flex flex-col gap-4">
            <p
              className={`${darkTheme ? `text-white` : `text-black`} font-bold`}
            >
              STEP 3
            </p>
            <p
              className={`${
                darkTheme ? `text-white ` : `text-black`
              } text-[16px] font-bold`}
            >
              Once authenticated, devices gain internet access through any
              connected node within the city area, ensuring a reliable and
              efficient user experience.
            </p>
          </div>
          <div
            className={`text-[128px] xs:text-[80px] ${
              darkTheme ? `text-primaryYellow` : `text-primaryBlue`
            } font-semibold`}
          >
            3
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;

import React, { useState, useRef } from "react";
import PersonalDetails from "./PersonalDetails";
import LocationDetails from "./Location Details";
import { sendEmail } from "../../helpers";

const ApplicationModal = ({ setState, darkTheme }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  return (
    <div className="w-full h-full fixed top-0 left-0 bg-gray-900 bg-opacity-70 z-50">
      <div
        className={`animate-slideUp bottom-0 w-full h-[90%] ${
          darkTheme
            ? `bg-primaryGray border-t-[1px] border-borderColor`
            : `bg-white`
        } flex flex-col items-center absolute rounded-tr-2xl rounded-tl-2xl px-[100px] xs:px-0 py-8 overflow-y-auto`}
      >
        <div
          className="w-full flex items-center justify-start gap-2 xs:gap-1 cursor-pointer p-2"
          onClick={() => {
            setState(false);
          }}
        >
          <img src="/assets/svg/arrow-left.svg" alt="arrow-left" />
          <span
            className={`${
              darkTheme ? `text-white` : `text-primaryBlue`
            } text-lg font-bold`}
          >
            Back to Home
          </span>
        </div>
        <div className="flex items-start flex-col px-20 py-10 xs:px-4 gap-10 xs:w-full">
          {/* <div className="flex items-center xs:w-full  justify-start gap-4">
            <span
              className={`text-mg font-bold cursor-pointer ${
                currentTab === 1 ? "text-primaryBlue" : "text-gray-500"
              }`}
              onClick={() => setCurrentTab(1)}
            >
              Personal Information
            </span>
            <img src="/assets/svg/caret-right.svg" alt="" />
            <span
              className={`text-mg font-bold cursor-pointer ${
                currentTab === 2 ? "text-primaryBlue" : "text-gray-400"
              }`}
              onClick={() => setCurrentTab(2)}
            >
              Location
            </span>
          </div> */}
          <form
            action=""
            ref={form}
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(
                form.current,
                "Application Received",
                "We will get back to you soon",
                setLoading,
                darkTheme,
                `Application Failed. Try again later`,
                `template_skrqxck`
              );
            }}
            className={`h-auto w-[800px] xs:w-full xs:px-4 border-2 ${
              darkTheme ? `border-borderColor border-[1px]` : `border-gray-300`
            } p-6 rounded-md items-center justify-center flex flex-col gap-10 xs:gap-4 py-10`}
          >
            <PersonalDetails
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              darkTheme={darkTheme}
            />
            <LocationDetails
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              closeModal={setState}
              isLoading={loading}
              darkTheme={darkTheme}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ApplicationModal;

import { useState } from "react";

const LocationDetails = ({ isLoading, darkTheme }) => {
  const [imageSrc, setImageSrc] = useState();
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imgUrl, setImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(``);

  function handleOnChange(changeEvent) {
    const reader = new FileReader();

    reader.onload = function (onLoadEvent) {
      setImageSrc(onLoadEvent.target.result);
    };

    reader.readAsDataURL(changeEvent.target.files[0]);
  }

  async function handleOnSubmit(e) {
    e.preventDefault();
    setUploading(true);

    const formData = new FormData();

    formData.append("file", imageSrc);
    formData.append("upload_preset", "my-uploads");

    const data = await fetch("https://api.cloudinary.com/v1_1/dztnevagf/image/upload", {
      method: "POST",
      body: formData,
    }).then((response) => {
      return response.json();
    });

    if (data?.secure_url) {
      setUploading(false);
      setImageUploaded(true);
      setTimeout(() => {
        setImageUploaded(false);
      }, 2000);
      setImageSrc(data?.secure_url);
      setImageUrl(data?.secure_url);
      setError(``);
    } else {
      setUploading(false);
      setError(`Failure to upload`);
    }
  }

  const restrictDecimalInput = (input) => {
    const regex = /^\d+(\.\d{0,2})?$/; // Regex to allow up to 2 decimal places
    if (!regex.test(input.target.value)) {
      input.target.value = input.target.value.slice(0, -1); // Remove the last character if it doesn't match the pattern
    }
  };

  return (
    <div className="w-full flex flex-col items-center gap-10">
      <div className={`flex flex-col ${darkTheme ? `text-textColor` : `text-textSecondaryColor`} gap-2 w-full`}>
        <label htmlFor="" className="flex items-center gap-2">
          Physical address and Google Earth Coordinates (start point and coverage area)
          <span className="text-center text-[#DB5F5F]">*</span>
        </label>
        <input
          type="text"
          className={`outline-none w-full border-b-2 border-textColor  text-gray-500 ${darkTheme && ` bg-primaryGray text-textColor`}`}
          required
          name="location"
        />
      </div>
      <div className={`flex flex-col ${darkTheme ? `text-textColor` : `text-textSecondaryColor`} gap-2 w-full`}>
        <label htmlFor="" className="flex items-center gap-2">
          Pictures/Video of the environment
          <span className="text-center text-[#DB5F5F]">*</span>
        </label>

        <label
          htmlFor="file"
          className={`w-full h-[200px] xs:h-[100px] ${
            darkTheme ? `border-[1px] border-textColor border-dashed` : `border-[1px] border-textColor  border-dashed`
          }  flex flex-col items-center justify-center mt-4 rounded-md cursor-pointer`}
        >
          {darkTheme ? <img src="/assets/svg/dark/folder.svg" alt="" /> : <img src="/assets/svg/folder.svg" alt="" />}
          <p className={darkTheme ? `text-textColor font-bold ` : `text-gray-500  font-bold `}>Upload images of the area</p>
          <input type="file" id="file" className="hidden" onChange={handleOnChange} />
          <input type="text" className="hidden" value={imgUrl} name="attachments" required />
        </label>
      </div>

      <div className="flex flex-col items-center justify-center gap-3">
        <img src={imageSrc || imgUrl} alt="" className="w-[50%] xs:w-[100%]" />
        {imageSrc && (
          <button className="btn-primary" onClick={handleOnSubmit}>
            {uploading ? `Uploading...` : ` Click to upload File`}
          </button>
        )}
        {imageSrc && imageUploaded ? <p className="text-green-200">Image uploaded successfully</p> : <p className="text-[#DB5F5F]">{error}</p>}
      </div>

      <div className="flex w-full gap-4 items-center xs:flex-col">
        <div className={`flex flex-col ${darkTheme ? `text-textColor` : `text-textSecondaryColor`} gap-2 w-full`}>
          <label htmlFor="" className="flex items-center gap-2">
            Expected Number of Users
            <span className="text-center text-[#DB5F5F]">*</span>
          </label>
          <input
            type="number"
            className={`outline-none w-full border-b-2 border-textColor  text-gray-500 ${darkTheme && ` bg-primaryGray text-textColor`}`}
            required
            name="no_of_users"
          />
        </div>
        <div className={`flex flex-col ${darkTheme ? `text-textColor` : `text-textSecondaryColor`} gap-2 w-full`}>
          <label htmlFor="" className="flex items-center gap-2">
            Length of the Street (Kilometers):
            <span className="text-center text-[#DB5F5F]">*</span>
          </label>
          <input
            type="number"
            step=".01"
            className={`outline-none w-full border-b-2 border-textColor  text-gray-500 ${darkTheme && ` bg-primaryGray text-textColor`}`}
            required
            name="length_of_street"
            onInput={restrictDecimalInput}
            // pattern="/^\d+(\.\d{1,2})?$/"
          />
        </div>
      </div>
      {/* </div> */}
      <input
        className="btn-primary w-[300px] cursor-pointer"
        value={isLoading ? `Submitting...` : `Submit Form`}
        type="submit"
        disabled={isLoading ? true : false}
      />
    </div>
  );
};

export default LocationDetails;

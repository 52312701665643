import React, { useState } from "react";
import { highModelCategories, normalModelCategory } from "../utils";

const ModelsCategory = ({ openModal, darkTheme }) => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <div
      className={`w-full h-auto ${
        darkTheme ? `bg-lightPrimaryBlack` : `bg-white`
      } flex flex-col  xs:mt-7 gap-20 xs:gap-16 px-[100px] xs:px-2 sm:px-4 py-[50px] xs:py-[8] transition-all duration-300 pt-[100px] xs:pt-[50px]`}
      id="models"
    >
      <div className="flex flex-col items-center justify-center w-full gap-4 border-t-[1px] border-borderColor pt-[50px]">
        <p
          className={`${
            darkTheme ? `text-white` : `text-primaryBlue`
          } font-semibold`}
        >
          Model Category
        </p>
        <div
          className={`${
            darkTheme ? `bg-primaryGray` : `bg-gray-200`
          } rounded-full xs:w-auto p-2 px-16 xs:px-10 xs:p-2 flex gap-4 items-center justify-center`}
        >
          <button
            className={
              currentTab === 1
                ? ` ${
                    darkTheme ? `text-white` : `text-white`
                  } btn-primary xs:text-[8px]`
                : `${
                    darkTheme ? `text-textColor` : `text-textSecondaryColor`
                  } xs:text-[8px]`
            }
            onClick={(e) => {
              e.preventDefault();
              setCurrentTab(1);
            }}
          >
            Optimal Model
          </button>
          <button
            className={
              currentTab === 2
                ? ` ${
                    darkTheme ? `text-white` : `text-white`
                  } btn-primary xs:text-[8px] p2-4`
                : `${
                    darkTheme ? `text-textColor` : `text-textSecondaryColor`
                  } xs:text-[8px]`
            }
            onClick={(e) => {
              e.preventDefault();
              setCurrentTab(2);
            }}
          >
            Standard Model
          </button>
        </div>
      </div>
      <div className="flex xs:flex-col items-center justify-center gap-4">
        {currentTab === 1 &&
          highModelCategories?.map((item) => (
            <div
              className={`h-[400px] xs:h-auto sm:h-[500px] md:h-auto w-[80%] xs:w-[90%] ${
                darkTheme ? `bg-primaryGray` : `bg-white`
              } border-[1px] rounded-3xl border-borderColor flex items-start  gap-10 xs:gap-4 flex-col p-[20px] xs:px-[20px] sm:p-[10px] px-[50px]`}
              key={item}
            >
              <div className=" relative w-full">
                <p
                  className={`${
                    darkTheme ? `text-white` : `text-black`
                  } text-[24px] xs:text-[18px] font-bold relative`}
                >
                  {item.name}
                </p>
                <div className="w-[91px] h-[5px] bg-primaryYellow absolute left-[10%] bottom-0 "></div>
              </div>
              <p
                className={`${
                  darkTheme
                    ? `text-white font-bold`
                    : `text-primaryBlue font-bold`
                } text-[20px]`}
              >
                Equipments needed
              </p>
              <div className="flex flex-col gap-2">
                {item?.options?.map((option) => (
                  <p
                    className={`w-full  flex items-center gap-2 ${
                      darkTheme ? `text-textColor` : `text-textSecondaryColor`
                    }  list-disc`}
                    key={option}
                  >
                    <span className="bg-textColor w-1 h-1 rounded-full "></span>
                    <span className="xs:text-sm text-[18px]">{option} </span> 
                  </p>
                ))}
              </div>
            </div>
          ))}
        {currentTab === 2 &&
          normalModelCategory?.map((item) => (
            <div
              className={`h-[400px] sm:h-[500px] md:h-auto w-[80%] xs:w-[90%] ${
                darkTheme ? `bg-primaryGray` : `bg-white`
              } border-[1px] rounded-3xl border-borderColor flex items-start  gap-10 xs:gap-4 flex-col xs:px-[20px]  p-[20px] px-[50px]`}
              key={item}
            >
              <div className=" relative w-full">
                <p
                  className={`${
                    darkTheme ? `text-white` : `text-black`
                  } text-[20px] xs:text-[18px] font-bold relative`}
                >
                  {item.name}
                </p>
                <div className="w-[91px] h-[5px] bg-primaryYellow absolute left-[10%] bottom-0 "></div>
              </div>{" "}
              <p
                className={`${
                  darkTheme
                    ? `text-white font-bold`
                    : `text-primaryBlue font-bold`
                } text-[20px]`}
              >
                Equipments needed
              </p>
              <div className="flex flex-col gap-2">
                {item?.options?.map((option) => (
                  <p
                    className={`w-full  flex items-center gap-2 ${
                      darkTheme ? `text-textColor` : `text-textSecondaryColor`
                    }  list-disc`}
                    key={option}
                  >
                    <span className="bg-textColor w-1 h-1 rounded-full"></span>
                    <span className="xs:text-sm text-[18px]">{option} </span> 
                  </p>
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ModelsCategory;

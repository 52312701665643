import React, { useRef, useState } from "react";
import { sendEmail } from "../helpers";
import PersonalDetails from "./Modals/PersonalDetails";
import LocationDetails from "./Modals/Location Details";

const ApplicationForm = ({ darkTheme }) => {
  const [currentTab, setCurrentTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const form = useRef();

  return (
    <div
      className={`w-full h-auto ${
        darkTheme ? `bg-lightPrimaryBlack` : `bg-white`
      } flex flex-col  xs:mt-7 gap-20 xs:gap-16 px-[100px] xs:px-6 sm:px-4  xs:py-[16] transition-all duration-300  pt-[100px] xs:pt-[50px]`}
      id="get-started"
    >
      <div className="flex flex-col items-center gap-8">
        <div className="text-center relative w-full">
          <p className={`${darkTheme ? `text-white` : `text-black`} text-[30px] xs:text-[24px] font-bold `}>Ready to Get Started?</p>
          <div className="w-[91px] xs:h-[5px] h-[10px] bg-primaryYellow absolute left-[45%] bottom-0 "></div>
        </div>
        <span className={`${darkTheme ? `text-gray-300` : `text-black`} text-[18px]`}>
          To bring Wicrypt City WiFi to your city, we will require some essential information:
        </span>
        <form
          action=""
          ref={form}
          onSubmit={(e) => {
            e.preventDefault();
            sendEmail(
              form.current,
              "Details submitted",
              "Your submission has been received and you’ll be contacted",
              setLoading,
              darkTheme,
              `Application Failed. Try again later`,
              `template_skrqxck`
            );
            e.target.reset();
          }}
          className={`h-auto w-[800px] sm:w-[80%] xs:w-full xs:px-4 border-2 ${
            darkTheme ? `border-borderColor border-[1px] bg-primaryGray` : `border-gray-300`
          } p-6 rounded-3xl flex flex-col gap-10 xs:gap-4 py-10`}
        >
          <PersonalDetails currentTab={currentTab} setCurrentTab={setCurrentTab} darkTheme={darkTheme} />
          <LocationDetails currentTab={currentTab} setCurrentTab={setCurrentTab} isLoading={loading} darkTheme={darkTheme} />
        </form>
      </div>
    </div>
  );
};

export default ApplicationForm;

import React from "react";

const Explantion = ({ darkTheme }) => {
  return (
    <div
      className={`w-full h-auto xs:h-auto sm:h-auto ${
        darkTheme ? `bg-lightPrimaryBlack` : `bg-white`
      } flex xs:flex-col  items-center mt-[50px] justify-center px-[100px] xs:px-8 sm:px-10 pt-[50px] xs:pt-4  transition-all duration-300`}
      id="about"
    >
      <div
        className={`flex items-center justify-center ${
          darkTheme ? `bg-lightPrimaryBlack` : `bg-white`
        } xs:flex-col-reverse w-full h-[90%] rounded  transition-all duration-300`}
      >
        <div className="flex-[0.5] xs:flex-[1] flex items-start flex-col gap-4 p-[20px] ">
          <p
            className={`${
              darkTheme ? `text-white` : `text-black`
            } text-[24px] xs:text-[18px] transition-all duration-300`}
          >
            What is City WiFi?
          </p>
          <p
            className={`${
              darkTheme ? `text-textColor` : `text-textSecondaryColor`
            } text-[20px] xs:text-[14px] transition-all duration-300`}
          >
            City WiFi is an innovative wireless mesh technology comprising
            interconnected nodes that work together to provide extensive
            coverage across large areas, such as estates, streets and
            communities. Say goodbye to connectivity dead zones and welcome a
            new era of high-speed internet access for your city area.
          </p>
        </div>
        <div className="flex-[0.5] flex items-center justify-center xs:flex-[1] p-4">
          {/* <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/bueNVRG1Oe0"
            title="YouTube video player"
            frameborder="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen=""
          ></iframe> */}
          <img src="assets/svg/wifi.svg" alt="" className=" w-[full] h-full scale-110" />
        </div>
      </div>
    </div>
  );
};

export default Explantion;

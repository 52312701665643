import React from "react";

const WhyHaveIt = ({ darkTheme }) => {
  return (
    <div
      className={`w-full h-auto xs:h-auto ${
        darkTheme ? `bg-primaryBlack` : `bg-blue-50`
      } flex flex-col  gap-10  px-[100px] xs:px-[30px] sm:px-10  transition-all duration-300 mb-[100px] py-[70px] xs:py-[50px]`}
      id="benefits"
    >
      <p
        className={`text-4xl xs:text-2xl ${
          darkTheme ? `text-white` : `text-black`
        } w-[30%] xs:w-full sm:w-full md:w-1/2 lg:w-2/3 font-bold leading-relaxed`}
      >
        Here’s Why You Should Opt For City WiFi In Your Community
      </p>
      <div className="flex xs:flex-col justify-center gap-4 xs:gap-4 item-center">
        <div
          className={`h-[236px] w-[478px] xs:w-full ${
            darkTheme ? `bg-primaryGray` : `bg-white`
          } border-[1px] rounded-2xl border-borderColor flex items-center  gap-4 flex-col p-[20px]  transition-all duration-300`}
        >
          {darkTheme ? (
            <img src="assets/svg/home-img-2.svg" alt=""/>
          ) : (
            <img src="assets/svg/home-img-1.svg" alt="" />
          )}
          <p
            className={`${
              darkTheme ? `text-white` : `text-primaryBlue`
            } font-bold text-[24px] xs:text-[16px]`}
          >
            Wide Coverage Area
          </p>
          <p
            className={`w-full text-center ${
              darkTheme ? `text-textColor` : `text-textSecondaryColor`
            }`}
          >
             Enjoy a coverage range of 1 km to 10 km, ensuring that every nook
            and cranny of your city area is connected.
          </p>
        </div>
        <div
          className={`h-[236px] w-[478px] xs:w-full ${
            darkTheme ? `bg-primaryGray` : `bg-white`
          } border-[1px] rounded-2xl border-borderColor flex items-center  gap-4 flex-col p-[20px] transition-all duration-300`}
        >
          {darkTheme ? (
            <img src="assets/svg/home-img-2.svg" alt="" />
          ) : (
            <img src="assets/svg/home-img-1.svg" alt="" />
          )}{" "}
          <p
            className={`${
              darkTheme ? `text-white` : `text-primaryBlue`
            } font-bold text-[24px] xs:text-[16px]`}
          >
            Multiple User Access
          </p>
          <p
            className={`w-full text-center ${
              darkTheme ? `text-textColor` : `text-textSecondaryColor`
            }`}
          >
            With the capacity to support up to 500 users, we cater to cities of
            all sizes, offering scalable solutions for your unique needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyHaveIt;

import React from "react";

const Header = ({ darkTheme, setDarkTheme }) => {
  return (
    <div
      className={`w-full xs:h-[50px] xs:px-[20px] xs:py-[30px] sm:px-[50px] h-[70px] text-white ${
        darkTheme ? `bg-[#111317]` : `bg-primaryBlue`
      } flex items-center justify-between px-[100px] py-[50px] sticky transition-all duration-300 top-0 left-0 z-20`}
    >
      <img src="assets/svg/logo.svg" alt="" className="xs:w-[60px]" />
      <nav className="flex items-center justify-end gap-8 xs:gap-4">
        <a href="#about" className="text-white text-sm xs:text-[8px]">
          About
        </a>
        <a href="#how-it-works" className="text-white text-sm xs:text-[8px]">
          How it works
        </a>
        <a href="#benefits" className="text-white text-sm xs:text-[8px]">
          Benefits
        </a>
        <a href="#models" className="text-white text-sm xs:text-[8px]">
          Models
        </a>
        <a href="#feedback" className="text-white text-sm xs:text-[8px]">
          Feedback
        </a>
        {darkTheme ? (
          <img
            src="assets/svg/sun.svg"
            alt="sun-img"
            onClick={() => setDarkTheme(false)}
            className="cursor-pointer xs:w-[18px]"
          />
        ) : (
          <img
            src="assets/svg/moon.svg"
            alt="moon-img"
            onClick={() => setDarkTheme(true)}
            className="cursor-pointer xs:w-[18px]"
          />
        )}
      </nav>
    </div>
  );
};

export default Header;

import emailjs from "@emailjs/browser";
import swal from "sweetalert";

export const sendEmail = (templateParams, successMessage, successText, setLoader, theme, errorMessage, templateId) => {
  setLoader(true);
  emailjs.sendForm("service_h2vkddw", templateId, templateParams, "3sDiF9YB-5V7LqpoU").then(
    function () {
      setLoader(false);
      swal({
        title: successMessage,
        text: successText,
        icon: "success",
        background: `${theme ? `#2B2F36` : `#ffffff`}`,
      });
    },
    function (err) {
      setLoader(false);
      swal({
        title: errorMessage,
        text: err.text,
        icon: "error",
        background: `${theme ? `#2B2F36` : `#ffffff`}`,
      });
    }
  );
};

import React from "react";

const Hero = ({ openModal, darkTheme }) => {
  return (
    <div
      className={`w-full xs:h-auto h-auto ${
        darkTheme ? `bg-primaryBlack` : `bg-primaryBlue`
      } flex xs:flex-col justify-between xs:justify-center px-[100px] transition-all duration-300 xs:px-8 sm:px-10 py-[50px] xs:gap-10 relative`}
    >
      <div className="flex-[0.5] flex flex-col xs:flex-[1] gap-8 xs:items-center">
        <p className="text-white text-[64px] xs:text-[30px] xs:text-center sm:text-[50px] ">
          Welcome to Wicrypt City WiFi
        </p>
        <p className="text-[24px] xs:text-[18px] text-white xs:text-center">
          Empowering Your Gated Community with Seamless Connectivity
        </p>
        <p className="mt-10">
          <a href="#get-started" className="btn-primary px-10 py-4">
            Get Started
          </a>
        </p>
      </div>
      <div className="flex-[0.5] xs:flex-[1] flex justify-center ">
        {/* <div className="w-[60%] sm:w-[80%] h-[90%] sm:h-[70%] md:h-[80%] bg-lightPrimaryBlue flex items-center justify-center rounded-2xl p-[20px] p-x-[40px]"> */}
        <img src="assets/svg/hero-img.svg" alt="" className="object-cover " />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Hero;

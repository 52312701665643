import React, { useState } from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Explanation from "../components/Explantion";
import HowItWorks from "../components/HowItWorks";
import WhyHaveIt from "../components/WhyHaveIt";
import ModelsCategory from "../components/ModelsCategory";
// import Devices from "../components/Devices";
import Feedback from "../components/Feedback";
import Footer from "../components/Footer";
import ApplicationModal from "../components/Modals/ApplicationModal";
import ImageSliderSection from "../components/ImageSliderSection";
import ApplicationForm from "../components/ApplicationForm";

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [darkTheme, setDarkTheme] = useState(true);

  return (
    <div
      className={
        darkTheme && `bg-lightPrimaryBlack transition-all duration-300`
      }
    >
      <Header darkTheme={darkTheme} setDarkTheme={setDarkTheme} />
      <Hero openModal={setShowModal} darkTheme={darkTheme} />
      <Explanation darkTheme={darkTheme} />
      <HowItWorks darkTheme={darkTheme} />
      <WhyHaveIt darkTheme={darkTheme} />
      <ModelsCategory openModal={setShowModal} darkTheme={darkTheme} />
      <ApplicationForm darkTheme={darkTheme} />
      {/* <Feedback darkTheme={darkTheme} /> */}
      <ImageSliderSection darkTheme={darkTheme} />
      {/* <Devices darkTheme={darkTheme} /> */}
      <Footer darkTheme={darkTheme} />
      {showModal && (
        <ApplicationModal setState={setShowModal} darkTheme={darkTheme} />
      )}
    </div>
  );
};

export default Home;

import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const ImageSliderSection = ({ darkTheme }) => {
  return (
    <div
      className={`pt-[70px] xs:pt-[50px] ${
        darkTheme ? `bg-lightPrimaryBlack` : `bg-white`
      }`}
    >
      <div
        className={`w-full xs:h-auto sm:h-auto h-[40vh] ${
          darkTheme ? `bg-primaryBlack` : `bg-blue-50`
        } flex flex-col md:flex-row sm:justify-center sm:items-center mt-[100px] xs:mt-[50px] mb-[100px] px-[100px] xs:px-4 sm:px-8 xs:gap-4 py-[50px] relative`}
      >
        <div className="flex flex-col gap-10 xs:gap-4 md:gap-4 ">
          {/* <p className="text-black text-[40px] xs:text-[20px] font-bold">
          Send a Feedback Today
        </p> */}
          <p
            className={` w-[40%] xs:w-full sm:w-[50%] ${
              darkTheme ? `text-white ` : `text-primaryBlue`
            } font-bold text-[26px] xs:text-[18px]`}
          >
            “Embrace the future of city-wide connectivity with Wicrypt City
            Wi-Fi. Say hello to reliable, fast, affordable internet access for
            all residents and visitors. Let's transform your city together!”
          </p>
        </div>
        <div
          className={` xs:h-[300px] min-h-[350px] w-[500px]  xs:w-full scale-150 sm:scale-100 xs:scale-100   rounded border-borderColor flex gap-4 justify-between flex-col px-[10px] absolute xs:relative sm:relative xs:right-0 sm:right-0 right-[200px] md:right-[50px] lg:right-[100px] xs:top-0 sm:top-0 top-[-65px] p-10 sm:w-[100%]`}
        >
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={10}
            isPlaying={true}
            interval={5000}
            className=" flex items-center justify-center h-auto w-full gap-4"
            isIntrinsicHeight={true}
          >
            <ButtonBack>
              <img src="/assets/svg/left-slide.svg" alt="" />
            </ButtonBack>
            <Slider className="h-auto w-full border-none">
              <Slide
                index={1}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-1.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={2}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-2.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={3}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-3.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={4}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-4.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={5}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-5.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={6}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-6.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={7}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-7.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={8}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-8.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={9}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-9.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
              <Slide
                index={10}
                className="h-auto w-full rounded-2xl border-none"
              >
                <img
                  src="/assets/png/slide-10.png"
                  alt=""
                  className="contain  rounded-2xl"
                />
              </Slide>
            </Slider>
            <ButtonNext>
              <img src="/assets/svg/right-slide.svg" alt="" />
            </ButtonNext>
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
};

export default ImageSliderSection;
